import InfoOutlined from "@material-ui/icons/InfoOutlined";
import ExportIcon from "@material-ui/icons/OpenInNew";
import WarningIcon from "@material-ui/icons/Warning";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCoverageStatus, useHcpProfilePlanInfoExport } from "../../api/useHcpProfileApi";
import { DataWindow, useDataWindows } from "../../api/useMetaDataApi";
import TableHeader from "../../components/Headers/TableHeader";
import SimpleTable from "../../components/Table/SimpleTable";
import { Column } from "../../components/Table/types";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useClientHCPProfilePlanInformationConfiguration } from "../../data/global/useProductConfiguration";
import { useHcpLandscapeState } from "../../data/hcpLandscape/hooks";
import { CoverageStatus as CoverageStatusType, SortDirection } from "../../generate";
import useHasAdtMktTrxTrans from "../../hooks/useHasAdtMktTrxTrans";

interface Props {
  indication?: string | undefined;
}

const PlanInformation: React.FC<Props> = ({ indication }) => {
  const { t } = useTranslation();
  const { client: selectedClient, id } = useParams<{ client: string; id: string }>();
  const { data: dataWindows } = useDataWindows(selectedClient);
  const { filters } = useHcpLandscapeState();
  const defaultDataWindow =
    (dataWindows || []).find((dataWindow) => dataWindow === DataWindow.twelveMonths) || DataWindow.twelveMonths;
  const timePeriod = filters.timePeriod || defaultDataWindow;
  const { data, isLoading } = useCoverageStatus(
    selectedClient,
    id,
    timePeriod,
    "bob_pct_ovrl",
    SortDirection.Descending,
    indication,
  );
  const PlanIndicator = "planIndicator" as const;

  const { dataKeys: allowedDataKeys } = useClientHCPProfilePlanInformationConfiguration(selectedClient);

  const hasAdtMktTrxTrans = useHasAdtMktTrxTrans(selectedClient);
  const mktTrxTrans = hasAdtMktTrxTrans
    ? t([`hcpProfile.adtMktTrx.${selectedClient}`])
    : t([`hcpProfile.mktTrx.${selectedClient}`]);

  const availableColumns: Column[] = useMemo(
    () => [
      {
        label: t("hcpProfile.plan"),
        align: "left",
        dataKey: "m_plan_name",
        tdClasses: "sm:bg-light-blue pl-0",
        component: PlanIndicator,
      },
      {
        label: t("hcpProfile.channel"),
        align: "center",
        dataKey: "channel",
      },
      {
        label: t("hcpProfile.coverageStatusWithName", { name: selectedClient.split("_")[0] }),
        align: "center",
        dataKey: "cc_status",
      },
      {
        label: t([`hcpProfile.relativeStatusWithName.${selectedClient}`, `hcpProfile.relativeStatusWithName.default`]),
        align: "center",
        dataKey: "rel_status",
      },
      {
        label: t([`hcpProfile.prodTrx.${selectedClient}`], { client: selectedClient }),
        align: "right",
        dataKey: "prod_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: t([`hcpProfile.compProdTrx.${selectedClient}`], { client: selectedClient }),
        align: "right",
        dataKey: "comp_prod_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: mktTrxTrans,
        align: "right",
        dataKey: "mkt_trx",
        format: {
          thousandSeparator: true,
        },
      },
      {
        label: t(`hcpProfile.prodShare`, { client: selectedClient?.split("_")[0] }),
        align: "right",
        dataKey: "prod_share",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: t([
          `hcpProfile.percentOfChannelBob.${timePeriod}.${selectedClient}`,
          `hcpProfile.percentOfChannelBob.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "bob_pct_chnl",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
      {
        label: t([
          `hcpProfile.percentOfBob.${timePeriod}.${selectedClient}`,
          `hcpProfile.percentOfBob.${timePeriod}.default`,
        ]),
        align: "right",
        dataKey: "bob_pct_ovrl",
        format: {
          suffix: "%",
          decimalScale: 1,
          fixedDecimalScale: true,
        },
      },
    ],
    [mktTrxTrans, selectedClient, t, timePeriod],
  );

  const columns: Column[] = useMemo(() => {
    return availableColumns.filter(({ dataKey }) => allowedDataKeys?.includes(dataKey as keyof CoverageStatusType));
  }, [availableColumns, allowedDataKeys]);

  const columnNames = columns.map((column) => t(column.label, column.translationParams));
  const columnKeys = columns.map((column) => column.dataKey);

  const exportTable = useHcpProfilePlanInfoExport(
    columnKeys,
    columnNames,
    id,
    selectedClient,
    timePeriod,
    "bob_pct_ovrl",
    SortDirection.Descending,
    indication,
  );

  return (
    <div className="pb-6">
      <TableHeader title={t("hcpProfile.planInformation")}>
        <Tooltip
          placement="bottom-end"
          tip={
            t([
              `hcpProfile.planInformationTooltip.${timePeriod}.${selectedClient}`,
              `hcpProfile.planInformationTooltip.${timePeriod}.default`,
            ]) as string
          }
        >
          <InfoOutlined className="text-primary" />
        </Tooltip>
      </TableHeader>
      <SimpleTable
        columns={columns}
        data={data}
        loading={isLoading}
        tableFooter={
          <tfoot>
            <tr>
              <td colSpan={10} className="sm:text-right p-0">
                <div className="bg-rules flex items-center justify-end">
                  <div className="flex">
                    <button className="btn p-0" disabled={exportTable.isLoading} onClick={() => exportTable.mutate()}>
                      {(exportTable.status === "idle" || exportTable.status === "success") && (
                        <span>{t("common.export")}</span>
                      )}
                      {exportTable.status === "loading" && <span>{t("common.exportLoading")}</span>}
                      {exportTable.status === "error" && (
                        <>
                          <WarningIcon /> <span>{t("common.export")}</span>
                        </>
                      )}
                    </button>

                    <button
                      className="btn p-0"
                      aria-label={t("common.export")}
                      disabled={exportTable.isLoading}
                      onClick={() => exportTable.mutate()}
                    >
                      <ExportIcon />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        }
      />
    </div>
  );
};

export default PlanInformation;
